
import { Options, Vue } from "vue-class-component";
import { mapActions, mapGetters } from "vuex";
@Options({
  data() {
    return {
      toggle: true,
    };
  },
  computed: {
    ...mapGetters(["instructionsState"]),
  },
  methods: {
    ...mapActions(["changeInstructions"]),
  },
})
export default class Instructions extends Vue {}
