
import { Options, Vue } from "vue-class-component";

@Options({
  props: {
    title: String,
    subtitle: String,
  },
})
export default class Headings extends Vue {
  msg!: string;
  subheading!: string;
}
