
import { mapGetters, mapActions } from "vuex";
import CopyCatImage from "./CopyCatImage.vue";
import { Options, Vue } from "vue-class-component";
@Options({
  components: { CopyCatImage },
  computed: {
    ...mapGetters({ selected_cat: "getChosenCat" }),
    getID() {
      return this.selected_cat.name
        ? this.selected_cat.name.replace("CopyCats", "")
        : "";
    },
  },
  methods: {
    ...mapActions({ changeCat: "selectCat", play: "setPlayStatus" }),
  },
})
export default class ChosenCat extends Vue {}
