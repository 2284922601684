import { resolveComponent as _resolveComponent, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Base = _resolveComponent("Base")!

  return (_openBlock(), _createBlock(_component_Base, {
    msg: "sketchette",
    class: "base",
    style: _normalizeStyle('backgroundColor: ' + _ctx.getBackground),
    subheading: "https://sketchette.com"
  }, null, 8, ["style"]))
}