import { openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3290b2cb"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["id", "width", "height"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("canvas", {
    id: _ctx.img,
    width: 24 * _ctx.scale,
    height: 24 * _ctx.scale,
    class: "border-white bg-white mx-auto cursor-pointer"
  }, null, 8, _hoisted_1))
}