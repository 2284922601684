import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([
      {
        'bg-purple-400 hover:bg-purple-800 cursor-pointer hover:text-white text-black':
          _ctx.noEthWallet,
      },
      'text-xs text-center p-1 w-1/2 md:w-1/4 rounded-full mx-auto  mt-5',
    ]),
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.ethConnect()))
  }, _toDisplayString(_ctx.getEthWallet == "error"
        ? "disconnect this wallet, refresh this page and connect to a wallet that has at least one head5."
        : _ctx.getEthWallet != ""
        ? "successfully connected to head5.io"
        : "connect to head5.io"), 3))
}