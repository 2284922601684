
import { Options, Vue } from "vue-class-component";
import { mapGetters } from "vuex";
import Base from "./components/Base.vue";

@Options({
  components: {
    Base,
  },
  computed: {
    ...mapGetters(["getBackground"]),
  },
})
export default class App extends Vue {}
